<template>
  <!-- Error page-->
  <b-card>
    <div class="misc-wrapper">
      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <h2 class="mb-1">
            Dilarang masuk! 🕵🏻‍♀️
          </h2>
          <p class="mb-2">
            Oops! Kamu tidak punya akses ke halaman / item tersebut.
          </p>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-sm-block"
            @click="$router.go(-1)"
          >
            Kembali ke halaman sebelumnya
          </b-button>

          <!-- image -->
          <Lottie
            class="unauth-lottie"
            :options="{
              animationData: unauthorized,
            }"
          />
        </div>
      </div>
    </div>
  </b-card>
<!-- / Error page-->
</template>

<script>
import { BButton, BCard } from 'bootstrap-vue'
import Lottie from 'vue-lottie'
import unauthorized from '@/assets/images/lottie/unauthorized.json'

export default {
  components: {
    BButton,
    BCard,
    Lottie,
  },
  data() {
    return {
      unauthorized,
    }
  },
}
</script>

<style>
  .misc-wrapper {
    min-height: 60vh !important;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

.unauth-lottie {
  width: 60%;
}
</style>
